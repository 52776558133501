.gameContainer {
    background-color: lightslategray;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.memoryCards {
    display: flex;
    flex-wrap: wrap;
    justify-content:flex-start;
    align-content: center;
    align-items: flex-start;
}

.wrongGuesses {
    font-size: 1.5rem;
    font-weight: 700;
}