.menu {
    width: 100%;
    background-color: hsla(218, 25%, 25%, 1);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.menu-item {
    background-color: hsla(218, 25%, 25%, 1);
    color:oldlace;
    font-size: 1.5em;
    font-weight: bold;
 
    padding: 20px;
}

.menu-button {
    background-color: rgb(69, 86, 116);
    color:oldlace;
    font-size: 1.5em;
    font-weight: bold;
    padding: 5px 10px;
    margin: 10px;
    border: 2px solid snow;
}

.menu-button:hover {
    background-color: rgb(94, 118, 158);
    cursor: pointer;
}

.menu-button:active {
    background-color: rgb(25, 32, 43);
    cursor:cell;
}