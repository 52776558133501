.card {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing:border-box;
    font-weight: bold;
    text-shadow: 0px 0px 2px grey, 0px 0px 4px white;
    transition: transform 0.2s;
    transition-property: background-color;
    transition-timing-function:ease-out;
    cursor: pointer;
}
